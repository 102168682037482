import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/CoinPurchase',
    name: 'CoinPurchase',
    component: () => import(/* webpackChunkName: "about" */ '../views/CoinPurchase/index.vue')
  },
  {
    path: '/CoinSales',
    name: 'CoinSales',
    component: () => import(/* webpackChunkName: "about" */ '../views/CoinSales/index.vue')
  },
  {
    path: '/Mypage',
    name: 'Mypage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mypage/index.vue')
  },
  {
    path: '/Notice',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "about" */ '../views/Notice/index.vue')
  },
  {
    path: '/admin',
    name: 'MemberAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/index.vue'),
    meta: { hideHeaderFooter: true }
  },
  {
    path: '/admin_list',
    name: 'ListAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/list.vue'),
    meta: { hideHeaderFooter: true }
  },
  {
    path: '/admin_board',
    name: 'BoardAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/board.vue'),
    meta: { hideHeaderFooter: true }
  },
  {
    path: '/admin_fee',
    name: 'FeeAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/fee.vue'),
    meta: { hideHeaderFooter: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
